import React, { RefObject, useEffect, useState } from "react";
import styles from "../../Page.scss";
import rootStyles from "~views/pages/Root.scss";
import { useStores } from "netbank-shared/src/hooks";
import calendarIcon from "~assets/calendar.svg";
import promiseToPayStyles from "./PromiseToPayPage.scss";
import { IPromiseToPayPage } from "netbank-shared/src/libs/models/Content/Page";
import { Button, Input } from "~views/shared";
import { tx } from "netbank-shared/src/libs/i18n";
import { TextArea } from "~views/shared/TextArea/TextArea";
import { FileAttachment } from "~views/shared/FileAttachment/FileAttachment";
import { DayPickerComponent } from "~views/shared/DayPicker/DayPicker";
import { acceptedDocumentExtensions, acceptedDocumentMimeTypes, formatDate } from "netbank-shared/src/libs/utils";
import { LabelRow } from "~views/shared/LabelRow/LabelRow";
import { Checkbox } from "~views/shared/Checkbox/Checkbox";
import errorIcon from "~assets/error.svg";
import { observer } from "mobx-react";
import { IBaseAccount, IPromiseToPayInvoiceInfo } from "netbank-shared/src/libs/models/CustomerProducts";
import { useNavigate } from "react-router-dom";
import { useDeterminePromiseToPayParamsByAccountType } from "netbank-shared/src/libs/determineLoadingParams";

interface IPromiseToPayFormData {
  data: IPromiseToPayPage;
  invoiceInfo: IPromiseToPayInvoiceInfo;
  goToAccountUrl: string;
  baseAccount: IBaseAccount;
}

export const PromiseToPayForm = observer(
  ({ data, invoiceInfo, goToAccountUrl, baseAccount }: IPromiseToPayFormData) => {
    const { customerStore } = useStores();

    const {
      promiseToPayDateCalcDate,
      promiseToPayPhoneNumber,
      creatingPromiseToPay,
      promiseToPayDateError,
      cantPayOnSelectableDates,
      promiseToPayMessageError,
      promiseToPayAttachments,
      setNewMessageBody,
      submitPromiseToPayRequest,
      addAttachments,
      removeAttachment,
      setCantPayOnSelectableDates,
      updatePromiseToPayCalcDate,
      setPromsieToPayPhoneNumber,
      cleanUp,
    } = useDeterminePromiseToPayParamsByAccountType(baseAccount.accountType, baseAccount.accountId);

    const dayPickerClasses = [promiseToPayStyles.dayPicker];
    const contentRef: RefObject<HTMLDivElement> = React.useRef(null);
    const calendarButtonRef: RefObject<HTMLButtonElement> = React.useRef(null);

    const minDate = invoiceInfo.dueDate ? new Date(invoiceInfo.dueDate) : new Date();
    minDate.setDate(minDate.getDate());

    const maxDate = invoiceInfo.dueDate ? new Date(invoiceInfo.dueDate) : new Date();
    maxDate.setDate(maxDate.getDate() + 14);

    const paymentInfoRows: { label: string; data?: string }[] = [
      {
        label: data.invoiceLabel || tx("invoice.invoiceNumber"),
        data: invoiceInfo.invoiceNumber,
      },
      {
        label: data.newDueDateLabel || tx("date.newDueDay"),
        data: formatDate(promiseToPayDateCalcDate),
      },
    ];

    const [open, setOpen] = useState(false);

    useEffect(() => {
      window.addEventListener("mousedown", handleClickOutside);
      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    });

    useEffect(() => {
      setPromsieToPayPhoneNumber(customerStore.currentCustomer?.mobilePhone.value ?? "");
    }, []);

    const handleClickOutside = (e: MouseEvent) => {
      if (contentRef && e.target instanceof Node && !contentRef.current?.contains(e.target)) {
        setOpen(false);
      }
    };

    if (open) {
      dayPickerClasses.push(promiseToPayStyles.open);
    }

    const navigate = useNavigate();

    const goBackToAccount = () => {
      cleanUp();
      navigate(goToAccountUrl);
    };

    return (
      <div className={promiseToPayStyles.promiseToPayWrapper}>
        <div className={promiseToPayStyles.dropdownWrapper}>
          <div ref={contentRef} className={promiseToPayStyles.dropdown}>
            <LabelRow
              label={data.dayInputLabel || tx("misc.paymentDate")}
              infoPopover={{
                popoverTitle: data.dayInputInfoTitle,
                content: data.dayInputInfoText,
                icon: "question",
              }}
            />
            <Button
              className={styles.dateButton}
              title={(promiseToPayDateCalcDate && formatDate(promiseToPayDateCalcDate)) || tx("misc.selectDate")}
              onClick={() => setOpen(!open)}
              borderColor="blue"
              active={open}
              iconSuffix={calendarIcon}
              bordered
              fullWidth
              ref={calendarButtonRef}
              disabled={cantPayOnSelectableDates || creatingPromiseToPay}
            />
            {promiseToPayDateError && (
              <div className={rootStyles.error}>
                <img src={errorIcon} alt="error-icon" />
                <span>{data.dayRequiredText || tx("loan.promiseToPay.required")}</span>
              </div>
            )}
            <div className={dayPickerClasses.join(" ")}>
              <DayPickerComponent
                onDayClick={(date: Date) => {
                  updatePromiseToPayCalcDate(date);
                  setOpen(false);
                }}
                initialMonth={promiseToPayDateCalcDate?.toDate()}
                selectedDays={promiseToPayDateCalcDate?.toDate()}
                disabledDays={[{ before: minDate, after: maxDate }]}
              />
            </div>
          </div>
        </div>
        <Input
          label={data.phoneInputLabel || tx("misc.phone")}
          className={promiseToPayStyles.contentWrapper}
          value={promiseToPayPhoneNumber}
          onChange={(e) => setPromsieToPayPhoneNumber(e.target.value)}
          infoPopover={{
            popoverTitle: data.phoneInputInfoTitle,
            content: data.phoneInputInfoText,
            icon: "question",
          }}
        />
        <div className={promiseToPayStyles.contentWrapper}>
          <Checkbox
            onChange={() => {
              setCantPayOnSelectableDates(!cantPayOnSelectableDates);
              updatePromiseToPayCalcDate(undefined);
            }}
            label={tx("loan.promiseToPay.cantPayOnSelectedCalenderDays")}
            disabled={creatingPromiseToPay}
          />
        </div>
        {cantPayOnSelectableDates && (
          <>
            <div className={promiseToPayStyles.contentWrapper}>
              <TextArea
                label={data.messageInputLabel || tx("misc.message")}
                placeholder={data.messageInputPlaceholder || tx("message.textareaPlaceholder")}
                onChange={(e) => {
                  setNewMessageBody(e.target.value);
                }}
                infoPopover={{
                  popoverTitle: data.messageInputInfoTitle,
                  content: data.messageInputInfoText,
                  icon: "question",
                }}
                error={
                  promiseToPayMessageError ? data.messageErrorText || tx("loan.promiseToPay.messageError") : undefined
                }
              />
            </div>
            <div className={promiseToPayStyles.contentWrapper}>
              <FileAttachment
                id="promiseToPay"
                infoPopover={{
                  popoverTitle: data.fileUploaderInfoTitle,
                  content: data.fileUploaderInfoText,
                  icon: "question",
                }}
                label={data.fileUploaderLabel || tx("file.desktopLabel")}
                desktopLabelSuffix={data.fileUploaderLabelSuffix}
                mobileLabel={data.fileUploaderLabel}
                acceptedMimeTypes={acceptedDocumentMimeTypes}
                acceptedExtensions={acceptedDocumentExtensions}
                attachments={promiseToPayAttachments}
                addAttachments={(attachments) => addAttachments(attachments)}
                removeAttachment={(fileId) => removeAttachment(fileId)}
              />
            </div>
          </>
        )}
        {promiseToPayDateCalcDate && (
          <div className={promiseToPayStyles.contentWrapper}>
            <LabelRow label={data.paymentInformationTitle || tx("loan.promiseToPay.paymentInformationTitle")} />
            <div className={promiseToPayStyles.paymentInfo}>
              {paymentInfoRows.map((p, i) => {
                return (
                  <div className={promiseToPayStyles.row} key={`paymentInfoRow-${i}`}>
                    <span className={promiseToPayStyles.label}>{p.label}</span>
                    <span>{p.data}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className={promiseToPayStyles.buttonContainer}>
          <Button
            large
            title={tx("selfServiceForm.goBack")}
            bordered
            borderColor="black"
            color="white"
            onClick={() => goBackToAccount()}
            className={promiseToPayStyles.button}
          />
          <Button
            title={data.promiseToPayRequestButtonLabel || tx("misc.send")}
            bordered
            centered
            large
            color="red"
            onClick={() => submitPromiseToPayRequest()}
            loading={creatingPromiseToPay}
            disabled={creatingPromiseToPay}
          />
        </div>
      </div>
    );
  },
);
