/* eslint-disable import/no-extraneous-dependencies */
import { useStores } from "netbank-shared/src/hooks";
import { AccountType, ILeaseAccount, ILoanAccount } from "netbank-shared/src/libs/models/CustomerProducts";
import { PromiseToPayStatusRequest } from "../models/Content/Enums";
import { Dayjs } from "dayjs";
import { IAttachment } from "libs/models/Attachment/Attachment";

export interface IPromiseToPayParams {
  currentAccount: ILoanAccount | ILeaseAccount | undefined;
  setPromsieToPayPhoneNumber: (number: string) => void;
  updatePromiseToPayCalcDate: (date?: Date) => void;
  setCantPayOnSelectableDates: (cantPay: boolean) => void;
  setNewMessageBody: (message: string) => void;
  addAttachments: (files: File[]) => void;
  removeAttachment: (fileId: string) => void;
  submitPromiseToPayRequest: () => void;
  cleanUp: () => void;
  promiseToPayStatus: PromiseToPayStatusRequest;
  promiseToPayAttachments: IAttachment[];
  promiseToPayDateCalcDate: Dayjs | undefined;
  promiseToPayPhoneNumber: string;
  creatingPromiseToPay: boolean;
  promiseToPayDateError: boolean;
  cantPayOnSelectableDates: boolean;
  promiseToPayMessageError: boolean;
}

export const useDeterminePromiseToPayParamsByAccountType = (
  accountType: AccountType | undefined,
  accountId: string | null | undefined,
): IPromiseToPayParams => {
  const { loanStore, leasingStore } = useStores();
  const emptyResponse: IPromiseToPayParams = {
    setPromsieToPayPhoneNumber: () => {},
    updatePromiseToPayCalcDate: () => {},
    setCantPayOnSelectableDates: () => {},
    setNewMessageBody: () => {},
    addAttachments: () => {},
    removeAttachment: () => {},
    submitPromiseToPayRequest: () => {},
    currentAccount: undefined,
    promiseToPayStatus: PromiseToPayStatusRequest.Initial,
    promiseToPayDateCalcDate: undefined,
    promiseToPayAttachments: [],
    promiseToPayPhoneNumber: "",
    creatingPromiseToPay: false,
    promiseToPayDateError: false,
    cantPayOnSelectableDates: false,
    promiseToPayMessageError: false,
    cleanUp: () => {},
  };
  if (!accountId || !accountType) return emptyResponse;
  switch (accountType) {
    case AccountType.SecuredLoan:
    case AccountType.PrivateLoan:
    case AccountType.SalesFinance:
      return {
        setPromsieToPayPhoneNumber: (number: string) => loanStore.setPromsieToPayPhoneNumber(number),
        updatePromiseToPayCalcDate: (date?: Date) => loanStore.updatePromiseToPayCalcDate(date),
        setCantPayOnSelectableDates: (cantPay: boolean) => loanStore.setCantPayOnSelectableDates(cantPay),
        setNewMessageBody: (message: string) => loanStore.setNewMessageBody(message),
        addAttachments: (files: File[]) => loanStore.addAttachments(files),
        removeAttachment: (fileId: string) => loanStore.removeAttachment(fileId),
        submitPromiseToPayRequest: () => loanStore.submitPromiseToPayRequest(),
        cleanUp: () => loanStore.resetPromiseToPayStates(),
        promiseToPayAttachments: loanStore.promiseToPayAttachments,
        currentAccount: loanStore.currentAccount,
        promiseToPayStatus: loanStore.promiseToPayStatus,
        promiseToPayDateCalcDate: loanStore.promiseToPayDateCalcDate,
        promiseToPayPhoneNumber: loanStore.promiseToPayPhoneNumber,
        creatingPromiseToPay: loanStore.creatingPromiseToPay,
        promiseToPayDateError: loanStore.promiseToPayDateError,
        cantPayOnSelectableDates: loanStore.cantPayOnSelectableDates,
        promiseToPayMessageError: loanStore.promiseToPayMessageError,
      };
    case AccountType.PrivateLeasing:
    case AccountType.Leasing:
      return {
        setPromsieToPayPhoneNumber: (number: string) => leasingStore.setPromsieToPayPhoneNumber(number),
        updatePromiseToPayCalcDate: (date?: Date) => leasingStore.updatePromiseToPayCalcDate(date),
        setCantPayOnSelectableDates: (cantPay: boolean) => leasingStore.setCantPayOnSelectableDates(cantPay),
        setNewMessageBody: (message: string) => leasingStore.setNewMessageBody(message),
        addAttachments: (files: File[]) => leasingStore.addAttachments(files),
        removeAttachment: (fileId: string) => leasingStore.removeAttachment(fileId),
        submitPromiseToPayRequest: () => leasingStore.submitPromiseToPayRequest(),
        cleanUp: () => leasingStore.resetPromiseToPayStates(),
        promiseToPayAttachments: leasingStore.promiseToPayAttachments,
        currentAccount: leasingStore.currentAccount,
        promiseToPayStatus: leasingStore.promiseToPayStatus,
        promiseToPayDateCalcDate: leasingStore.promiseToPayDateCalcDate,
        promiseToPayPhoneNumber: leasingStore.promiseToPayPhoneNumber,
        creatingPromiseToPay: leasingStore.creatingPromiseToPay,
        promiseToPayDateError: leasingStore.promiseToPayDateError,
        cantPayOnSelectableDates: leasingStore.cantPayOnSelectableDates,
        promiseToPayMessageError: leasingStore.promiseToPayMessageError,
      };
    default:
      return emptyResponse;
  }
};
