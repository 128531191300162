import { DKPathNames } from "../routing";

export default {
  translation: {
    locale: "da",
    routing: {
      ...DKPathNames,
      term: "betingelser-for-netbanken",
    },
    routeNames: {
      home: "Oversigt",
      transfers: "Overførsler",
      messages: "Beskeder",
      card: "Kreditkort",
      loan: "Lån",
      deposit: "Opsparingskonto",
      leasing: "Leasing",
      menu: "Menu",
    },
    validators: {
      required: "Stat {{- name, lower}}",
      email: "Den e-mailadresse, du indtastede, er ikke gyldig",
      phone: "Ugyldig {{- name, lower}}",
      pidn: "Dit indtastede personnummer er ikke gyldigt",
      maxLength: "Maksimal længde er {{- max }} tegn",
    },
    invoice: {
      overdueAmount: "Forfaldent beløb",
      movedToDebtCollection: "Flyttet til inkasso",
      accountName: "Kontonavn",
      accountNumber: "Kontonummer",
      autogiro: "Direkte debitering",
      bankgiro: "Bankoverførsel",
      customerNumber: "Kundenummer",
      loadMore: "Se flere fakturaer",
      disclaimer: `For ikke at blive opkrævet renter betaler du {{- amount }} denne måned. Beløbet inkluderer rentebærende indkøb,
              kontanthævninger og eventuelle gebyrer.`,
      downloadInvoice: "Ladda hem PDF",
      showInvoice: "Vis faktura",
      dueDate: "Forfaldsdato",
      invoice: "Faktura",
      invoiceAndDate: "Faktura {{- date, lower}}",
      invoiceDate: "Fakturadato",
      invoiceDetails: "Fakturaoplysninger",
      invoiceNumber: "Faktura nummer",
      invoicePeriod: "Faktureringsperiode",
      amountToPay: "Beløb at betale",
      minimumPayment: "Minimumsbeløb at betale",
      maximumPayment: "Maksimalt beløb at betale",
      ocr: "Referencenummer (OCR)",
      plusGiro: "plusGiro",
      stopped: "Fakturaen stoppes.",
      stopText: `Vi har modtaget dokumentation vedrørende din klage. Fakturering er standset afventende
              resultatet af den eksterne eksamen. Har du spørgsmål, så kontakt os på telefon {{- phoneNumber }}.`,
      total: "Hele kreditbeløbet",
      balanceAtDate: "Saldo per den",
      seeInvoice: "Se faktura",
      status: "Status",
      titles: {
        cancelled: "Produktet er udgået, {{- date, lower}}",
        overdue: "Forfalden faktura, {{- date, lower}}",
        currentPlaceholder: "Aktuel fakturaa",
        current: "Aktuel faktura, {{- date, lower}}",
        extraPayment: "Vil du betale ekstra?",
        paymentFreeMonth: "Vil du have en betalingsfri måned?",
      },
      statuses: {
        due: "Ubetalt",
        cancel: "Annulleret",
        paid: "Betald",
        overdue: "Förfallen",
        replaced: "Ersatt",
        pending: "Inom respit",
      },
      emptyState: {
        header: "Inga resultat hittades!",
        text: "",
      },
      daysDelayed: "dage forsinket",
      remainder: "Resterende beløb",
    },
    card: {
      accountNumber: "Kontonummer",
      accountName: "Kontonavn",
      accountType: "Kontotype",
      brokenCard: "Ødelagt kort",
      credit: "Kredit",
      coApplicant: "Medlåntager",
      card: "Kort",
      insurance: "Forsikring",
      lostCard: "Tabt kort",
      autogiro: "Direkte debitering",
      pfm: "Betalingsfri måned",
      cardNumber: "Kortnummer",
      expiresAt: "Gyldig endda",
      creditLimit: "Kredit grænse",
      orderCard: "Bestil kort",
      order: "Bestille",
      hasCard: "Kort er tilgængelige",
      activateCard: "Aktiver kortet",
      status: "Kortstatus",
      blockCard: "Bloker kort",
      debtor: "Debitor",
      activeCardLabel: "Aktiv",
      blockedCardLabel: "Blokeret",
      expiredCardLabel: "Inaktiv",
      inactiveCardLabel: "Inaktiv",
      closedAccountsBannerText: "<b>kreditkonti er lukket</b>",
      closedAccountBannerText: "<b>kreditkontoen er lukket</b>",
      insuranceInfoLabel: "Safe Extra",
    },
    deposit: {
      withdrawalTimeLabel: "Varslingsperiode",
      transferTimeLabel: "Overførselstid",
      myDepositPlusWithdrawalTime: "31 dage",
      myDepositWithdrawalTime: " 1-3 dage",
      myDepositWithdrawalInfo:
        "Der går normalt mellem 1-3 bankdage fra effekturingsdatoen til pengene står på din {toAccount}.",
      myDeposiPlustWithdrawalInfo:
        "Der går normalt 31 dage fra effekturingsdatoen til pengene står på din {toAccount}.",
      openFTDWithdrawalInfo:
        "FTD: Der går normalt mellem 1-3 bankdage fra effekturingsdatoen til pengene står på din {toAccount}.",
      activatedFTDWithdrawalInfo:
        "FTD activated: Der går normalt mellem 1-3 bankdage fra effekturingsdatoen til pengene står på din {toAccount}.",
      freeOfCharge: "Gratis",
      totalSavingAmount: "I alt",
      chooseTransferAccount: "Vælg udbetalingskonto",
      chooseReceiverOrCreate: "Vælg gemt konto eller indtast ny",
      accountInformation: "Kontooplysninger",
      accruedInterest: "Ikke tilskrevne renter",
      tax: "Skat",
      totalTransferAmount: "I alt skal udbetales",
      closingDate: "Lukningsdato",
      openDate: "Oprettelsesdato",
      accountRedemptionErrorHeader: "Noget gik galt",
      accountRedemptionErrorText: "Noget gik galt. Prøv igen senere",
      receiverSuccessLabel: "Dine penge vil blive udbetalt til",
      receiverAccount: "Hævekonto",
      accountOpenDateLabel: "Oprettet dato",
      accountActivationDateLabel: "Bindingsperioden starter",
      accountMaturityDateLabel: "Bindingsperioden slutter",
      closeAccount: "Opsig konto",
      closeAccountConfirmation: "Opsig konto",
      closeAccountWarningText: "Er du sikker på at du vil lukke din konto, denne handling kan ikke fortrydes.",
      availableBalance: "Disponibel saldo",
      bookedBalance: "Rentebærende saldo",
      interestCarryingBalance: "Rentebærende saldo",
      terminationFee: "Afslutningsgebyr",
      expirationDate: "Bindingsperiodens udløb",
      nationalPayoutAccountInformation: "Udbetaling sker altid til din NemKonto.",
      FTDMaturityDateTemplateStringText: "<p><strong>Låst</strong> – Kontoen er låst til og med: {maturityDate}</p>",
      FTDActivationDateTemplateStringText:
        "<p><strong>{activationDaysLeftText} tilbage at indbetale</strong> – Du kan indbetale penge til og med: {activationDate}</p>",
      FTDMaturityDateTitle: "Renten er fast til og med",
      FTDActivationDateTitle: "Sidste indbetalingsdag",
      FTDDepositPageMaturityDateTemplateStringText:
        "<strong>Kontoen er låst til og med {maturityDate}</strong><br>Dine penge er låst i X måneder til en fast rente (X%).",
      FTDDepositPageActivationDateTemplateStringText:
        "<strong>Du har {activationDaysLeftText} tilbage til at indbetale penge</strong><br>Du kan stadig sætte penge ind på din konto til og med {activationDate}, hvorefter kontoen vil blive låst.",
      FTDDepositToAccountDisabledText:
        "Det er ikke længere muligt at indbetale på kontoen, da indbetalingsfristen er overskredet",
      FTDDepositFromAccountDisabledText:
        "Indbetalingsperiode for denne konto er udløbet. Hvis du ønsker at hæve dine penge, inden indbetalingsperiode udløber, bedes du kontakte kundeservice.",
      FTDWithdrawalInfo:
        "Den faste renten er bundet til og med {maturityDate}, indskud og udbetalinger er låst indtil udløbsdatoen.",
      FTDActivationDaysLeftText: "{activationDaysLeft} dage",
      FTDLastActivationDayText: "i dag",
      bindingPeriod: "Forpligtelsesperiode",
      earlyDebitFeeRateLabel: "Udbetalingsgebyr i bindingsperioden",
      fee: "Gebyr",
      minimumInterestBearingAmountLabel: "Minimum rentebærende beløb",
      minimumInterestBearingAmountInfo: "Minimum rentebærende beløb er {minimumInterestBearingAmount}",
      closedAccountsBannerText: "<b>Opsparingskonti er lukket</b>",
      closedAccountBannerText: "<b>Opsparingskontoen er lukket</b>",
    },
    loan: {
      mainDebtor: "Debitor",
      paymentHolidayMonths: "Betalingsfrie måneder",
      loanTerminations: {
        amountLabel: "Beløb",
        calcButtonLabel: "Få infrielsesbeløb",
        consentButtonLabel: "Indsend samtykke",
        goBackButtonLabel: "Luk",
        registrationNumber: "Registreringnummer",
        accountNumber: "Kontonummer",
        referenceNumber: "Referencenummber",
        ibanNumber: "IBAN",
        loadingAccountError: "Noget gik galt. Prøv at genindlæse siden.",
        goBack: "Luk",
        exportDocument: "eksporter som pdf",
        settlementDocumentFileName: "Indfrielsesbeløb.pdf",
      },
    },
    selfServiceForm: {
      resultHeader: "Tak!",
      goBack: "Luk",
      unexpectedErrorText: "Noget gik galt. Prøv igen senere",
    },
    leasing: {
      accountNumberLabel: "Kontonummer",
      additionalInformation: "Andre oplysninger",
      openDate: "Oprettet dato",
      feesAndInterest: "Gebyrer og renter",
      contractPeriod: "Kontraktperiode",
      elapsedPeriod: "Forløbet periode",
      invoiceFee: "Fakturagebyr",
      accountInterest: "Rente",
      months: "måneder",
      monthsShort: "måneder.",
      description: "Beskrivelse",
      licensePlate: "Registreringsnummer",
    },
    transfer: {
      fromAccount: "Fra konto",
      toAccount: "Til konto",
      amount: "Beløb",
      bank: "Bank",
      transferMoney: "Overføre penger",
      accountNumber: "Kontonummer",
      addNewAccount: "Tilføj ny konto",
      clearingNumber: "Clearingnummer",
      manageSavedAccounts: "Administrer gemte konti",
      addAccount: "Tilføj konto",
      chooseReceiver: "Vælg modtager...",
      newReceiverLabel: "Ny modtager",
      addNewReceiver: "Tilføje en modtager",
      savedAccountsLabel: "Gemte modtagere",
      amountToTransfer: "Beløb, der skal overføres",
      balanceAfterTransfer: "Tilgængelig efter overførsel",
      bankTransferFee: "Udbetalingsgebyr",
      transferErrorHeader: "Noget gik galt",
      transferErrorText: "Noget gik galt. Prøv igen senere",
      newTransfer: "Foretag en ny overførsel",
      ownedAccountsLabel: "Dine konti",
      accountName: "Kontonavn",
      availableAmount: "Disponible saldo",
      transferedAmount: "Overført beløb",
      withdrawalSuccessHeader: "Udbetaling afsluttet",
      withdrawalErrorHeader: "Noget gik galt",
      transferSuccessHeader: "Overførsel afsluttet",
      withdrawalSuccessText: "Udbetaling afsluttet",
      transferFallbackText: "Transaktionen lykkedes",
      forbiddenTransferError: "Uautoriseret overførsel. Kontrollér, at du har valgt de rigtige konti.",
      transferDetailsApiError:
        "Noget gik galt. Prøv igen senere. Hvis fejlen fortsætter, venligst kontakte kundeservice.",
      account: "konto",
      requireManualHandlingHeader: "Utilstrækkelig saldo",
      requireManualHandlingText:
        "<p>Du har for få penge på din konto til at foretage overførslen. Kontakt venligst kundeservice for at hæve pengene.</p>",
    },
    misc: {
      chooseProduct: "Vælg produkt",
      chooseACard: "Vælg kort",
      fee: "Oplade",
      interest: "Interesse",
      effectiveInterest: "Effektiv rente",
      title: "Net Bank",
      logout: "Log ud",
      published: "Udgivet",
      name: "Navn",
      accountNumber: "Kontonummer",
      accountName: "Kontonavn",
      balance: "Saldo",
      date: "Dato",
      purchaseDate: "Købsdato",
      dateRange: "Datointerval",
      foreignAmount: "Beløb i anden valuta",
      exchangeRate: "Valutakurs",
      reservedAmount: "Det reserverede beløb",
      amount: "Beløb",
      receiver: "Modtager",
      accountingDate: "Regnskabsdato",
      transactionDate: "Transaktionsdag",
      interestDate: "Renteberegningsdag",
      Date: "Betalingsdato",
      fromAccount: "Fra konto",
      message: "Besked",
      messages: "Beskeder",
      ocrMessage: "OCR-nummer/meddelelse",
      youHave: "Du har",
      unreadMessage: "ulæst beskede",
      unreadMessages: "ulæste beskeder",
      unpaidInvoice: "ubetalt faktura",
      unpaidInvoices: "ubetalte fakturaer",
      and: "og",
      search: "Søg",
      cancel: "Annuller",
      apply: "Vælg",
      applyFor: "Vælg",
      amountFrom: "Fra beløb",
      amountTo: "Til beløbp",
      dateFrom: "Fra dato",
      dateTo: "Til dato",
      selectDate: "Vælg dato",
      clear: "Nulstil",
      clearFilter: "Nulstil filteret",
      select: "Vælg",
      email: "E-post",
      mobile: "Mobilnummer",
      phone: "Telefonnummer",
      nameAdress: "Navn & adresse",
      sms: "SMS",
      internetbank: "Internetbank",
      saved: "Gemt",
      updateInfo: "Opdatering",
      downloadInfo: "Hent",
      licensePlate: "Registreringsnummer",
      fetchMore: "Hent mere",
      showLess: "Vis mindre",
      yes: "Ja",
      no: "Nej",
      invoiceFee: "Fakturagebyr",
      bankTransferFee: "Pris bankoverførsel",
      atmFee: "Hævningsomkostninger i hæveautomatg",
      currencyExchangeFee: "Omkostninger valutaveksling",
      status: "Status",
      active: "Aktiv",
      inactive: "Inaktiv",
      ssn: "CPR-nummer",
      storeName: "Beskrivelse",
      atLeast: "Mindst",
      upTo: "Op til",
      exists: "Eksisterer",
      missing: "Eksisterer ikke",
      notActive: "Ikke aktiveret",
      other: "Andet",
      send: "Sende",
      delete: "Slet",
      save: "Gem",
      reserved: "Reservatet",
      exportXLSX: "Eksport til regneark (XLSX)",
      headline: "Overskrift",
      document: "Dokument",
      terminate: "Ende",
      close: "Luk",
      summary: "Opsummering",
      previous: "Foregående",
      next: "Næste",
      questions: "Spørgsmål",
      completed: "afsluttet",
      sign: "Underskrive",
      onwards: "Videre",
      back: "Tilbage",
      chooseCitizenshipPlaceholder: "Begynd at skriv, vælg et land fra listen",
      somethingWentWrong: "Noget gik galt",
      information: "Information",
      seeTerms: "Se vilkår",
      contractNumber: "Kontrakt nummer",
      payment: "Betaling",
      administrationFee: "Administrationsgebyr",
      insuranceFee: "Forsikringsgebyr",
      installment: "Rate",
      remainingDebt: "Udestående gæld",
      reload: "Genindlæs",
      showDetails: "Vis detaljer",
      ok: "OK",
      choose: "Vælg",
      paymentReference: "Betalingsinformation",
      contractDownloadError:
        "Noget gik galt. Prøv igen senere. Hvis fejlen fortsætter, venligst kontakte kundeservice.",
      organizationNumber: "Organisationsnummer",
      organizationName: "Virksomhed",
      productType: "Kontotype",
      viewAll: "Vis alle",
      viewLess: "Vis mindre",
      years: "år",
    },
    file: {
      desktopLabel: "Træk filer her eller ",
      desktopLabelSuffix: "upload",
      mobileLabel: "Upload filer her",
      maxFileSizeLabel: "Maksimal filstørrelse",
      maxCombinedFileSizeLabel:
        "Den samlede filstørrelse overskrider {{- limit}}. Reducer venligst antallet af filer, eller vælg mindre filer.",
      maxAttachmentsError: "Det maksimale antal tilladte vedhæftede filer er {{- count}}.",
      acceptedExtensionsLabel: "Tilladte filformater",
      acceptedExtensions: "{{- extensions, to_sentence}}",
      attachmentValidationError:
        "Uploading mislykkedes. Undgå duplikerede filnavne, og sørg for kun at bruge {{- extensions, to_sentence}} filer. Hver fil bør ikke overstige 10 MB.",
      duplicateAttachmentOrSizeError:
        "Uploading mislykkedes. En eller flere af de valgte filer er allerede uploadet. Hver fil må ikke overstige 10 MB.",
      singleFileAttachmentError:
        "Noget gik galt, da du uploadede {{- fileName}}. Tjek venligst filformatet og filstørrelsen, og prøv igen..",
    },
    date: {
      periodOfTime: "Periode",
      last30: "Sidste 30 dage",
      last3Months: "Sidste 3 måneder",
      thisYear: "Indtil videre i år",
      months: "måneder",
    },
    emptyState: {
      header: "Ingen resultater blev fundet!",
      text: "Prøv igen senere!",
    },
    login: {
      header: "Log ind",
      text: "I Interbetbanken kan du som privatkunde nemt klare dine bankforretninger, når du vil.",
      placeholder: "Indsende personnummer",
      buttonText: "Log ind",
      rememberMe: "Husk mig",
      mockData: "Mockdata",
      generalErrorHeader: "Login blev afbrudt",
      generalErrorText: "Noget gik galt, mens du loggede ind. Prøv venligst at logge ind igen.",
      generalErrorTryAgainText: "Prøv igen",
      loginIn: "Logger ind...",
      updateRequired: "Du skal downloade den seneste version af appen for at logge ind.",
      santanderHomepageURL: "https://www.santanderconsumer.se",
    },
    message: {
      inbox: "Inbox",
      archive: "Arkiv",
      toArchive: "Arkivera",
      selectAccountText: "Vælg konto",
      selectSubjectText: "Vælg konto",
      selectAreaText: "Vælg område",
      accountLabelText: "Vælg din konto",
      subjectLabelText: "Vælg et emne",
      areaLabelText: "Vælg et område",
      composeNewMessage: "Skriv ny besked",
      newMessage: "Ny besked",
      sendMessage: "Send besked",
      textareaPlaceholder: "Skriv din besked her",
      textareaReplyPlaceholder: "Klik for at skrive dit svar",
      attach: "Bilag",
      faq: "Almindelige spørgsmål",
      loadMore: "Se flere e-mails",
      reply: "Svare",
      prevDialog: "Tidligere dialog",
    },
    placeholders: {
      chooseAccount: "Vælg konto",
      year: "ÅÅÅÅ",
      month: "MM",
    },
    retryPage: {
      title: "Åh.",
      text: "Noget gik galt. Prøv at genindlæse siden.",
      reloadLink: "Genindlæs",
    },
    langPicker: {
      label: "Vælg sprog",
      dk: "dansk",
      en: "English",
    },
    withdrawal: {
      nationalPayoutAccount: "NemKonto",
      withdrawal: "Udbetaling",
      nationalPayoutAccountInfo: "Herefter kan der gå 1-2 dage før de står på din Nemkonto.",
    },
    compliance: {
      bannerButton: "Til spørgsmålene",
      validationErrorText: "Der opstod en uventet fejl. Kontrollér, at alle svar er udfyldt.",
      unexpectedErrorText: "Noget gik galt. Prøv igen senere",
      processingHeader: "Tak!",
      processingText: "Vi behandler i øjeblikket ansøgningen. Vent venligst.",
    },
    updateContactInfo: {
      changeInfo: "ændre info",
      phoneLabel: "Telefon",
      emailLabel: "Email",
      streetLabel: "Gade",
      postCodeLabel: "Postnummer",
      cityLabel: "By",
      countryLabel: "Land",
      personalPreferences: {
        byEmail: "Via e-mail",
        byTextMessage: "Med sms",
      },
    },
    accountNickname: {
      accountSetNameValidationErrorText:
        "Kontonavnet må kun indeholde bogstaver og tal, ingen specialtegn. Den maksimale længde på kontonavnet er 20 tegn.",
      updateAccountNameValidationErrorText: "Noget gik galt.",
      noCurrentAccountErrorText: "Noget gik galt. Prøv igen senere",
      updateAccountName: "Opdater kontonavn",
    },
    selfServiceLabels: {
      termsCheckboxLabel: "Jeg accepterer ovenstående vilkår og betingelser.",
    },
    paymentHoliday: {
      monthsPickerLabel: "Vælg",
    },
    increaseLoan: {
      loanAmountLabel: "Lånebeløb",
      termsLabel: "Tilbagebetalingsperiode",
      installments: "ydelser",
    },
  },
};
