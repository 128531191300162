import { ApiResponse, ApisauceInstance, create } from "apisauce";
import dayjs from "dayjs";
import { IConfig } from "../models/Config";
import { Environment } from "../models/Content/Enums";
import {
  IConsentReply,
  ILoanAccountApiResponse,
  ILoanAccountSubmitTermination,
  ILoanAccountTerminationDetails,
  ILoanAccountsApiResponse,
  IPaymentsResponse,
  IGetPaymentsRequest,
  IGetInvoicesRequest,
  IInvoicesResponse,
  IDocument,
  InvoiceDistributionFormatType,
  LoanTerminationDebtor,
  ICreateApplicationResponse,
  IApplication,
} from "libs/models/CustomerProducts";
import { Store } from "stores";
import { MockLoanApi } from "./mock/MockLoanApi";
import MockAdapter from "axios-mock-adapter";
import { AxiosAdapter, AxiosInstance } from "axios";
import { IDueDayResponse, IUpdateDueDayFormInfo } from "../models/UpdateDueDay";
import { IPowerOfAttorneyTerms } from "../models/PowerOfAttorney";
import { IPromiseToPayData } from "../models/PromiseToPay";
import {
  IAddInstallmentForm,
  IExtendResidualAmountPaymentForm,
  IIncreaseInstallmentsForm,
  IMileageChangeForm,
  IMileageChangeResponse,
  IReduceInstallmentsForm,
} from "../forms/PaymentPlanChange";
import { UpdateInstallmentsAction } from "../forms/UpdateInstallments";
import { EmploymentType, checkHasEmployer } from "../forms/SharedFormDropdowns";
import { IPowerOfAttorneyForm } from "../forms/PowerOfAttorneyForm";
import { IAddInsuranceForm } from "../forms/Insurances/AddInsuranceForm";
import { ITerminateInsuranceForm } from "../forms/Insurances/TerminateInsuranceForm";
import { IPaymentHolidayForm } from "../forms/PaymentHoliday/PaymentHolidayForm";
import { IIncreaseLoanForm } from "../forms/IncreaseLoan/IncreaseLoanForm";

export class LoanApi {
  rootStore: Store;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": config.OCP_APIM_SUBSCRIPTION_KEY,
        "x-country": config.COUNTRY_CODE,
      };

      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: config.LOAN_URL,
        headers,
        timeout: 60000,
      });

      this.client.addMonitor(this.handleResponse);

      if (config.OCTOPUS_ENV !== Environment.Production) this.setupMockAdapter();
    }
  };

  handleResponse = async (response: ApiResponse<any>) => {
    // Remove token and redirect to login page on 401
    if (response.status === 401 || !this.rootStore.securePersistentStore.accessToken) {
      await this.rootStore?.logout();
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
    },
  });

  getLoanAccounts = async () => this.client?.get<ILoanAccountsApiResponse>("", {}, this.getHeaders());

  getLoanAccount = async (accountId: string) =>
    this.client?.get<ILoanAccountApiResponse>(`${accountId}`, {}, this.getHeaders());

  getPayments = async ({ accountId, dateFrom, dateTo, paymentType }: IGetPaymentsRequest) => {
    return this.client?.get<IPaymentsResponse>(
      `/${accountId}/payments`,
      { dateFrom, dateTo, paymentType },
      this.getHeaders(),
    );
  };

  getInvoices = async ({ accountId, invoiceStatus }: IGetInvoicesRequest) => {
    return this.client?.get<IInvoicesResponse>(`/${accountId}/invoices`, { invoiceStatus }, this.getHeaders());
  };

  getLoanAccountTerminationDetails = async (
    accountId: string,
    date?: string,
    debtor?: LoanTerminationDebtor,
    download?: boolean,
  ) =>
    this.client?.get<ILoanAccountTerminationDetails>(
      `${accountId}/terminations`,
      {
        terminationDate: date,
        debtor,
        download,
      },
      this.getHeaders(),
    );

  submitLoanAccountTermination = async (accountId: string, terminationDate?: string, consent?: IConsentReply[]) =>
    this.client?.post<ILoanAccountSubmitTermination>(
      `${accountId}/terminations`,
      {
        terminationDate,
        consentReplies: consent?.map((consentReply) => {
          return {
            questionId: consentReply.questionId,
            answerId: consentReply.answerId,
            contactPhoneNumber: consentReply.contactPhoneNumber,
          };
        }),
      },
      this.getHeaders(),
    );

  getContracts = async (accountId: string) =>
    this.client?.get<IDocument[]>(`${accountId}/contracts`, {}, this.getHeaders());

  getContractDocument = async (accountId: string, documentId: string) =>
    this.client?.get<IDocument>(`${accountId}/contracts/${documentId}`, {}, this.getHeaders());

  getCurrentDueDay = async (accountId: string) =>
    this.client?.get<IDueDayResponse>(`/${accountId}/due-days`, {}, this.getHeaders());

  updateDueDay = async (accountId: string, data: IUpdateDueDayFormInfo) =>
    this.client?.put(
      `/${accountId}/due-days`,
      {
        dueDay: data.selectedDay,
        phoneNumber: data.phoneNumber,
      },
      this.getHeaders(),
    );

  getPowerOfAttorneyTerms = async (accountId: string) =>
    this.client?.get<IPowerOfAttorneyTerms>(`/${accountId}/power-of-attorneys`, {}, this.getHeaders());

  createPowerOfAttorney = async (accountId: string, data: IPowerOfAttorneyForm) => {
    const formData = new FormData();
    formData.append("travelDate", data.travelDate?.toISOString() ?? "");
    formData.append("insuranceCompany", data.insuranceCompany?.toString() ?? "");
    formData.append("travellingToInvalidCountry", JSON.stringify(data.travellingToInvalidCountry));
    formData.append("acceptTermsAndExpense", JSON.stringify(data.acceptTermsAndExpense));

    data.countries?.forEach((country) => {
      if (country) {
        formData.append("countries", country.value);
      }
    });

    data.attachments?.forEach((file) => {
      if (file) {
        formData.append("attachments", file);
      }
    });

    return this.client?.post(
      `/${accountId}/power-of-attorneys`,
      formData,
      this.getHeaders({ "Content-Type": "multipart/form-data" }),
    );
  };

  promiseToPayInvoice = async (accountId: string, invoiceNumber: string, data: IPromiseToPayData) => {
    const formData = new FormData();
    const promiseToPayServiceRequest = JSON.stringify(data.promiseToPayServiceData);

    formData.append("cantPayOnSelectableDates", JSON.stringify(data.cantPayOnSelectableDates));
    formData.append("message", data.message ?? "");
    formData.append("phoneNumber", data.phoneNumber ?? "");
    formData.append("dueDate", data.dueDate ?? "");
    formData.append("promiseToPayServiceRequest", promiseToPayServiceRequest);

    data.attachments?.forEach((f) => {
      formData.append("attachments", f);
    });

    return this.client?.put(
      `/${accountId}/invoices/${invoiceNumber}`,
      formData,
      this.getHeaders({ "Content-Type": "multipart/form-data" }),
    );
  };

  changeInvoiceMethod = async (accountId: string, InvoiceDistributionFormat: InvoiceDistributionFormatType) =>
    this.client?.put(
      `/${accountId}/invoice-methods`,
      {
        InvoiceDistributionFormat,
      },
      this.getHeaders(),
    );

  requestMileageChange = async (accountId: string, data: IMileageChangeForm) => {
    const formData = new FormData();
    formData.append("currentMileage", data.currentMileage.toString());
    formData.append("requestedMileage", data.requestedMileage);
    formData.append("phone", data.phone);

    if (data.message) formData.append("message", data.message);
    if (data.attachments) {
      Array.from(data.attachments).forEach((f) => {
        formData.append("attachments", f);
      });
    }

    return this.client?.post<IMileageChangeResponse>(
      `${accountId}/mileages`,
      formData,
      this.getHeaders({ "Content-Type": "multipart/form-data" }),
    );
  };

  getInvoiceDocument = async (accountId: string, invoiceNumber: string) =>
    this.client?.get<Blob>(
      `${accountId}/invoice-documents/${invoiceNumber}`,
      {},
      { ...this.getHeaders(), responseType: "blob" },
    );

  addInstallment = async (accountId: string, data: IAddInstallmentForm) => {
    return this.client?.post(
      `${accountId}/installments`,
      {
        amount: data.amount,
        date: dayjs(data.date).endOf("day").toISOString(),
        installmentType: data.installmentType,
        phone: data.phone,
      },
      this.getHeaders(),
    );
  };

  reduceInstallments = async (accountId: string, data: IReduceInstallmentsForm) => {
    const formData = new FormData();
    formData.append("action", UpdateInstallmentsAction.ReduceInstallmentRequest);
    formData.append("amount", data.amount.toString());
    formData.append("phone", data.phone);
    formData.append("employmentType", data.employmentType);
    formData.append("housingType", data.housingType);
    formData.append("income", data.income.toString());

    if (checkHasEmployer(data.employmentType as EmploymentType)) {
      formData.append("employer", data.employer!);
      formData.append("occupation", data.occupation!);
    }

    if (data.mileage) formData.append("currentMileage", data.mileage.toString());
    if (data.message) formData.append("reasonMessage", data.message);
    if (data.attachments) {
      Array.from(data.attachments).forEach((f) => {
        formData.append("attachments", f);
      });
    }

    return this.client?.put(
      `${accountId}/installments`,
      formData,
      this.getHeaders({ "Content-Type": "multipart/form-data" }),
    );
  };

  increaseInstallments = async (accountId: string, data: IIncreaseInstallmentsForm) => {
    const formData = new FormData();
    formData.append("action", UpdateInstallmentsAction.IncreaseInstallmentRequest);
    formData.append("amount", data.amount.toString());
    formData.append("increaseInstallmentType", data.increaseInstallmentType);
    formData.append("phone", data.phone);

    return this.client?.put(
      `${accountId}/installments`,
      formData,
      this.getHeaders({ "Content-Type": "multipart/form-data" }),
    );
  };

  extendResidualAmountPayment = async (accountId: string, data: IExtendResidualAmountPaymentForm) => {
    const formData = new FormData();
    formData.append("action", UpdateInstallmentsAction.ExtendResidualAmountPaymentRequest);
    formData.append("amount", data.amount.toString());
    formData.append("currentMileage", data.mileage.toString());
    formData.append("phone", data.phone);
    formData.append("employmentType", data.employmentType);
    formData.append("housingType", data.housingType);
    formData.append("income", data.income.toString());

    if (checkHasEmployer(data.employmentType as EmploymentType)) {
      formData.append("employer", data.employer!);
      formData.append("occupation", data.occupation!);
    }

    if (data.message) formData.append("reasonMessage", data.message);
    if (data.attachments) {
      Array.from(data.attachments).forEach((f) => {
        formData.append("attachments", f);
      });
    }

    return this.client?.put(
      `${accountId}/installments`,
      formData,
      this.getHeaders({ "Content-Type": "multipart/form-data" }),
    );
  };

  addInsurance = async (accountId: string, data: IAddInsuranceForm) => {
    return this.client?.post(
      `${accountId}/insurances`,
      {
        phone: data.phone,
        message: data.message,
      },
      this.getHeaders(),
    );
  };

  terminateInsurance = async (accountId: string, data: ITerminateInsuranceForm) => {
    return this.client?.delete(
      `${accountId}/insurances`,
      {
        terminationReason: data.terminationReason,
        phone: data.phone,
        message: data.message,
      },
      this.getHeaders(),
    );
  };

  addPaymentHoliday = async (accountId: string, data: IPaymentHolidayForm) => {
    return this.client?.post(
      `${accountId}/payment-holidays`,
      {
        months: data.months?.map((month) => dayjs(month).endOf("day").toISOString()),
      },
      this.getHeaders(),
    );
  };

  getApplications = async (accountId: string) => {
    return this.client?.get<IApplication[]>(`${accountId}/applications`, {}, this.getHeaders());
  };

  createIncreaseLoanApplication = async (accountId: string, data: IIncreaseLoanForm) => {
    return this.client?.post<ICreateApplicationResponse>(`${accountId}/applications`, data, this.getHeaders());
  };

  setupMockAdapter = () => {
    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 2000,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    MockLoanApi(this.mock);
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
