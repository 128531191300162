import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { IConfig } from "libs/models/Config";
import { Environment } from "../models/Content/Enums";
import {
  IAccountTransactions,
  ICreditAccountApiResponse,
  ICreditAccountsApiResponse,
  IDocument,
  IGetTransactionsRequest,
} from "libs/models/CustomerProducts";
import { Store } from "stores";
import MockAdapter from "axios-mock-adapter";
import { AxiosAdapter, AxiosInstance } from "axios";
import { MockCreditApi } from "./mock/MockCreditApi";

export class CreditApi {
  rootStore: Store;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": config.OCP_APIM_SUBSCRIPTION_KEY,
        "x-country": config.COUNTRY_CODE,
      };

      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: config.CREDIT_URL,
        headers,
        timeout: 60000,
      });

      this.client.addMonitor(this.handleResponse);

      if (config.OCTOPUS_ENV !== Environment.Production) this.setupMockAdapter();
    }
  };

  handleResponse = async (response: ApiResponse<any>) => {
    // Remove token and redirect to login page on 401
    if (response.status === 401 || !this.rootStore.securePersistentStore.accessToken) {
      await this.rootStore?.logout();
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
    },
  });

  getCreditAccounts = async () => this.client?.get<ICreditAccountsApiResponse>("", {}, this.getHeaders());

  getCreditAccount = async (accountId: string) =>
    this.client?.get<ICreditAccountApiResponse>(accountId, {}, this.getHeaders());

  getTransactions = async ({ accountId, nextToken, amountFrom, amountTo, dateFrom, dateTo }: IGetTransactionsRequest) =>
    this.client?.get<IAccountTransactions>(
      `${accountId}/transactions`,
      {
        nextToken,
        amountFrom,
        amountTo,
        dateFrom,
        dateTo,
      },
      this.getHeaders(),
    );

  getContracts = async (accountId: string) =>
    this.client?.get<IDocument[]>(`${accountId}/contracts`, {}, this.getHeaders());

  getContractDocument = async (accountId: string, documentId: string) =>
    this.client?.get<IDocument>(`${accountId}/contracts/${documentId}`, {}, this.getHeaders());

  setupMockAdapter = () => {
    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 2000,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    MockCreditApi(this.mock);
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
