import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { IConfig } from "libs/models/Config";
import { Environment } from "../models/Content/Enums";
import {
  IDocument,
  IGetInvoicesRequest,
  IGetPaymentsRequest,
  IInvoicesResponse,
  ILeaseAccountApiResponse,
  ILeaseAccountsApiResponse,
  IPaymentsResponse,
  InvoiceDistributionFormatType,
} from "libs/models/CustomerProducts";
import { Store } from "stores";
import MockAdapter from "axios-mock-adapter";
import { AxiosAdapter, AxiosInstance } from "axios";
import { MockLeasingApi } from "./mock/MockLeasingApi";
import { IPromiseToPayData } from "libs/models/PromiseToPay";
import { IMileageChangeForm, IMileageChangeResponse } from "libs/forms/PaymentPlanChange";
import { IPowerOfAttorneyTerms } from "libs/models/PowerOfAttorney";
import { IPowerOfAttorneyForm } from "libs/forms/PowerOfAttorneyForm";
import { IAddInsuranceForm } from "libs/forms/Insurances/AddInsuranceForm";
import { ITerminateInsuranceForm } from "libs/forms/Insurances/TerminateInsuranceForm";

export class LeasingApi {
  rootStore: Store;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": config.OCP_APIM_SUBSCRIPTION_KEY,
        "x-country": config.COUNTRY_CODE,
      };

      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: config.LEASING_URL,
        headers,
        timeout: 60000,
      });

      this.client.addMonitor(this.handleResponse);

      if (config.OCTOPUS_ENV !== Environment.Production) this.setupMockAdapter();
    }
  };

  handleResponse = async (response: ApiResponse<any>) => {
    // Remove token and redirect to login page on 401
    if (response.status === 401 || !this.rootStore.securePersistentStore.accessToken) {
      await this.rootStore?.logout();
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
    },
  });

  getLeasingAccounts = async () => this.client?.get<ILeaseAccountsApiResponse>("", {}, this.getHeaders());

  getLeasingAccount = async (accountId: string) =>
    this.client?.get<ILeaseAccountApiResponse>(`${accountId}`, {}, this.getHeaders());

  getInvoices = async ({ accountId, invoiceStatus }: IGetInvoicesRequest) => {
    return this.client?.get<IInvoicesResponse>(`/${accountId}/invoices`, { invoiceStatus }, this.getHeaders());
  };

  getPayments = async ({ accountId, dateFrom, dateTo, paymentType }: IGetPaymentsRequest) => {
    return this.client?.get<IPaymentsResponse>(
      `/${accountId}/payments`,
      { dateFrom, dateTo, paymentType },
      this.getHeaders(),
    );
  };

  promiseToPayInvoice = async (accountId: string, invoiceNumber: string, data: IPromiseToPayData) => {
    const formData = new FormData();
    const promiseToPayServiceRequest = JSON.stringify(data.promiseToPayServiceData);

    formData.append("cantPayOnSelectableDates", JSON.stringify(data.cantPayOnSelectableDates));
    formData.append("message", data.message ?? "");
    formData.append("phoneNumber", data.phoneNumber ?? "");
    formData.append("dueDate", data.dueDate ?? "");
    formData.append("promiseToPayServiceRequest", promiseToPayServiceRequest);

    data.attachments?.forEach((f) => {
      formData.append("attachments", f);
    });

    return this.client?.put(
      `/${accountId}/invoices/${invoiceNumber}`,
      formData,
      this.getHeaders({ "Content-Type": "multipart/form-data" }),
    );
  };

  getContracts = async (accountId: string) =>
    this.client?.get<IDocument[]>(`${accountId}/contracts`, {}, this.getHeaders());

  getContractDocument = async (accountId: string, documentId: string) =>
    this.client?.get<IDocument>(`${accountId}/contracts/${documentId}`, {}, this.getHeaders());

  changeInvoiceMethod = async (accountId: string, invoiceDistributionFormat: InvoiceDistributionFormatType) =>
    this.client?.put(
      `/${accountId}/invoice-methods`,
      {
        invoiceDistributionFormat,
      },
      this.getHeaders(),
    );

  requestMileageChange = async (accountId: string, data: IMileageChangeForm) => {
    const formData = new FormData();
    formData.append("currentMileage", data.currentMileage.toString());
    formData.append("requestedMileage", data.requestedMileage);
    formData.append("phone", data.phone);

    if (data.message) formData.append("message", data.message);
    if (data.attachments) {
      Array.from(data.attachments).forEach((f) => {
        formData.append("attachments", f);
      });
    }

    return this.client?.post<IMileageChangeResponse>(
      `${accountId}/mileages`,
      formData,
      this.getHeaders({ "Content-Type": "multipart/form-data" }),
    );
  };

  getPowerOfAttorneyTerms = async (accountId: string) =>
    this.client?.get<IPowerOfAttorneyTerms>(`/${accountId}/power-of-attorneys`, {}, this.getHeaders());

  createPowerOfAttorney = async (accountId: string, data: IPowerOfAttorneyForm) => {
    const formData = new FormData();
    formData.append("travelDate", data.travelDate?.toISOString() ?? "");
    formData.append("insuranceCompany", data.insuranceCompany?.toString() ?? "");
    formData.append("travellingToInvalidCountry", JSON.stringify(data.travellingToInvalidCountry));
    formData.append("acceptTermsAndExpense", JSON.stringify(data.acceptTermsAndExpense));

    data.countries?.forEach((country) => {
      if (country) {
        formData.append("countries", country.value);
      }
    });

    data.attachments?.forEach((f) => {
      formData.append("attachments", f);
    });

    return this.client?.post(
      `/${accountId}/power-of-attorneys`,
      formData,
      this.getHeaders({ "Content-Type": "multipart/form-data" }),
    );
  };

  getInvoiceDocument = async (accountId: string, invoiceNumber: string) =>
    this.client?.get<Blob>(
      `${accountId}/invoice-documents/${invoiceNumber}`,
      {},
      { ...this.getHeaders(), responseType: "blob" },
    );

  addInsurance = async (accountId: string, data: IAddInsuranceForm) => {
    return this.client?.post(
      `${accountId}/insurances`,
      {
        phone: data.phone,
        message: data.message,
      },
      this.getHeaders(),
    );
  };

  terminateInsurance = async (accountId: string, data: ITerminateInsuranceForm) => {
    return this.client?.delete(
      `${accountId}/insurances`,
      {
        terminationReason: data.terminationReason,
        phone: data.phone,
        message: data.message,
      },
      this.getHeaders(),
    );
  };

  setupMockAdapter = () => {
    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 2000,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    MockLeasingApi(this.mock);
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
