import { useStores } from "../../hooks";
import { AccountType, IPayment, InvoiceDistributionFormatType } from "../models/CustomerProducts";

export const useDetermineInvoiceLoadingParamsByAccountType = (
  accountType: AccountType | undefined,
  accountId: string,
): {
  invoice: IPayment | undefined | null;
  loadingFunction: () => void;
  loading: boolean;
  error: boolean;
  invoiceAction: (invoice: IPayment) => void;
  currentInvoiceMethod: InvoiceDistributionFormatType | undefined;
  getInvoiceDocument: (invoiceNumber: string) => void;
  fetchingInvoiceDocument: boolean;
} => {
  const { loanStore, leasingStore } = useStores();
  switch (accountType) {
    case AccountType.PrivateLoan:
    case AccountType.SecuredLoan:
    case AccountType.SalesFinance:
      return {
        invoice: loanStore.duePayment,
        loadingFunction: () => {
          loanStore.getDuePayment(accountId);
        },
        loading: loanStore.loadingDuePayment,
        error: loanStore.loadingDuePaymentError,
        currentInvoiceMethod: loanStore.currentAccount?.invoiceDistributionFormat,
        invoiceAction: (invoice: IPayment) => {
          loanStore.setActivePayment(invoice);
        },
        getInvoiceDocument: (invoiceNumber: string) => {
          loanStore.getInvoiceDocument(invoiceNumber);
        },
        fetchingInvoiceDocument: loanStore.fetchingInvoiceDocument,
      };
    case AccountType.PrivateLeasing:
    case AccountType.Leasing:
      return {
        invoice: leasingStore.duePayment,
        loadingFunction: () => {
          leasingStore.getDuePayment(accountId);
        },
        loading: leasingStore.loadingDuePayment,
        error: leasingStore.loadingDuePaymentError,
        currentInvoiceMethod: leasingStore.currentAccount?.invoiceDistributionFormat,
        invoiceAction: (invoice: IPayment) => {
          leasingStore.setActivePayment(invoice);
        },
        getInvoiceDocument: (invoiceNumber: string) => {
          leasingStore.getInvoiceDocument(invoiceNumber);
        },
        fetchingInvoiceDocument: leasingStore.fetchingInvoiceDocument,
      };
    default:
      return {
        invoice: undefined,
        loadingFunction: () => {},
        loading: false,
        error: true,
        currentInvoiceMethod: undefined,
        invoiceAction: () => {},
        getInvoiceDocument: () => {},
        fetchingInvoiceDocument: false,
      };
  }
};
