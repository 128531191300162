import { useStores } from "../../hooks";
import { AccountType, IPayment, ITransaction } from "../models/CustomerProducts";

interface ITransactionLoadingParams {
  loadTransactions: (dateFrom?: string, dateTo?: string) => void;
  transactions: IPayment[] | ITransaction[] | undefined;
  loading: boolean;
  error: boolean;
  invoiceAction?: (invoice: IPayment) => void;
}

export const useDetermineTransactionLoadingParamsByAccountType = (
  accountType: AccountType | undefined,
  accountId: string | null,
): ITransactionLoadingParams => {
  const { loanStore, depositStore, creditStore, leasingStore } = useStores();
  const emptyResponse: ITransactionLoadingParams = {
    loadTransactions: () => {},
    transactions: [],
    loading: false,
    error: true,
    invoiceAction: () => {},
  };
  if (!accountId || !accountType) return emptyResponse;
  switch (accountType) {
    case AccountType.PrivateLoan:
    case AccountType.SecuredLoan:
    case AccountType.SalesFinance:
      return {
        loadTransactions: () => {
          loanStore.getPaidPayments(accountId);
        },
        transactions: loanStore.paidPayments,
        loading: loanStore.loadingPaidPayments,
        error: loanStore.loadingPaidPaymentsError,
        invoiceAction: (invoice: IPayment) => {
          loanStore.setActivePayment(invoice);
        },
      };
    case AccountType.Deposit:
      return {
        loadTransactions: (dateFrom: string | undefined, dateTo: string | undefined) => {
          depositStore.getTransactions(accountId, dateFrom, dateTo);
        },
        transactions: depositStore.transactions,
        loading: depositStore.loadingTransactions,
        error: depositStore.loadingTransactionsError,
      };
    case AccountType.Credit:
      return {
        loadTransactions: (dateFrom: string | undefined, dateTo: string | undefined) => {
          creditStore.getTransactions(accountId, dateFrom, dateTo);
        },
        transactions: creditStore.transactions,
        loading: creditStore.loadingTransactions,
        error: creditStore.loadingTransactionsError,
      };
    case AccountType.Leasing:
    case AccountType.PrivateLeasing:
      return {
        loadTransactions: () => {
          leasingStore.getPaidPayments(accountId);
        },
        transactions: leasingStore.paidPayments,
        loading: leasingStore.loadingPaidPayments,
        error: leasingStore.loadingPaidPaymentsError,
        invoiceAction: (invoice: IPayment) => {
          leasingStore.setActivePayment(invoice);
        },
      };
    default:
      return emptyResponse;
  }
};
