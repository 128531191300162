export interface IInvoice {
  invoiceNumber: number;
  commonInvoiceNumber?: number;
  productName: string;
  accountNumber: string;
  accountGroup: string;
  billedAmount: number;
  invoiceDate: string;
  ocrReference: string;
  paymentAccount: string;
  invoiceStatus: string;
}

export interface IAccountInvoices {
  invoices: IInvoiceStatement[];
}

export interface IInvoiceStatement {
  documentId: string;
  maturityDate?: string;
  amount: number;
  ocrReference: string;
  paymentAccount: string;
  status?: string;
  documentTypeId: string;
}

export interface IPromiseToPayInvoiceInfo {
  invoiceNumber?: string;
  dueDate?: string;
  invoiceAmount?: number;
}

export enum InvoiceDistributionFormatType {
  SantanderInvoice = "SantanderInvoice",
  PaperInvoice = "PaperInvoice",
}

export enum InvoiceUpdateStatus {
  Initial = "Initial",
  Success = "Success",
  Failed = "Failed",
}
